<template>
<div>
    <div class="breadcrumb_header">
        <div class="breadcrumb">
        <h4 style="margin:0;padding-left: 10px;">NeXa</h4>
        <span class="divider"></span>
        <span>Upload Files</span>
        </div>
      <div class="open_sidebar" @click="toggleSidebar">
        <span class="material-symbols-rounded icon">keyboard_double_arrow_left</span>
        <p>Open Sidebar</p>
      </div>
  </div>
  <div class="row">
    <div class="col-md-1 mt-3">
      <div class="d-flex justify-content-start cursor-pointer back" @click="goBack">
      <span class="material-symbols-rounded" style="margin: 0;">arrow_back</span>
      <span style="margin: 0 8px;">Back</span>
    </div>
    </div>
    <div class="col-md-10 mt-5"> 
       <div v-if="completedPercentage < 100">
          <div class="skeleton-loader">Loading files...</div>
        </div> 
        <div v-else>
            <main>
            <section class="uploads">
                <h4>Uploaded files</h4>
                <a href="#">View Uploaded Files</a>
                <div class="d-flex mt-3 align-items-center">
                  <span class="material-symbols-rounded" style="font-size: 20px;">info</span>
                  <p class="mb-0 ms-2" style="font-size: 12px;">
                    Your files will be securely stored in NeXa, accessible only to you and your workspace admins, and you can delete them at any time.
                  </p>
                </div>

                
                <!-- <div class="button-container">
                    <button class="add-files-button">
                        <span class="material-symbols-rounded icon">add</span>
                        Add more files
                    </button>
                </div> -->

                </section>
                <section class="use-cases">
                <div class="use-case-section">
                    <div class="use-case-header">
                        <h4>Ask NeXa</h4>
                        <p>Here’s what NeXa can do with your uploaded files</p>
                    </div>
                    <div class="filter-search">
                        <div class="search-container">
                            <span class="material-symbols-rounded icon-search">search</span>
                            <input type="text" placeholder="Search" class="search-input" />
                        </div>

                    </div>
                    </div>
                 <div class="use-case-list">
                    <article
                      v-for="(useCase, index) in useCases"
                      :key="useCase.id"
                      class="use-case"
                      :class="{ 'full-width': expandedIndex === index }"
                      @click="toggleWidth(index)"
                  >
                  <h5>{{ useCase.title }}</h5>
                  <div v-if="useCase.description">
                    <p>{{ useCase.description }}</p>
                    </div>
                    <div v-else>
                      <p class="skeleton"></p>
                      <p class="skeleton"></p>
                    </div>
                  <p class="mt-2 mb-2">Follow-up Question:</p>
                  <article class="search-content" v-if="expandedIndex === index">
                    <p>
                      
                    </p>
                    <div class="ask-container">
                      <input
                        type="text"
                        placeholder="Enter your follow-up question here."
                        v-model="followUpQuestion"
                        @click.stop
                      />
                      <span
                        class="material-symbols-rounded icon"
                        @click.stop="sendQuestion(useCase.id)"
                      >
                        send
                      </span>
                    </div>
                  </article>
                    </article>
              </div>
                </section>
            </main>
        </div> 
    </div>
    <div class="col-md-1">
    </div>
    </div>

    <aside class="sidebar overlay-container" v-if="sidebarCollapsed">
      <button class="sidebar-toggle" @click="toggleSidebar">
        <span class="material-symbols-rounded icon">double_arrow</span>
        <span>Close Sidebar</span>
      </button>
      <header class="sidebar-header">
        <button class="new-chat-button">
          <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/88990e141e40e027023be95963b1fabf0d50a62b7ee5cfdfe49b8dadef2916af?placeholderIfAbsent=true&apiKey=b7b3d02748134357b1767f32084c4983" alt="New Chat" class="icon" />
          <span>New Chat</span>
        </button>
        <form class="search-form">
          <label for="search" class="visually-hidden">Search</label>
          <input type="text" id="search" placeholder="Search" aria-label="Search" class="search-input" />
          <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/5df906573a5faa9ed54311d4653d575cafb33930e9f4ab04dac2653585abc1a0?placeholderIfAbsent=true&apiKey=b7b3d02748134357b1767f32084c4983" alt="Search Icon" class="search-icon" />
        </form></header>
      <section class="item-list">
        <hr class="separator" />
        <h4>Uploaded Files</h4>
        <p>Files uploaded to this chat/use case.</p>
        <ul>
          <li v-for="(doc, index) in questionDocuments" :key="index" class="d-flex">
            <!-- <span class="material-symbols-rounded">draft</span> -->
            <a :href="doc.file" target="_blank">{{ doc.name }} ({{ doc.file_type }}) </a>
          </li>
        </ul>
        
      </section>
    </aside>
</div>
</template>
<script>
// import Nexa from './detail.vue';
import nexa from '@/store/nexa.js';
export default {
    components: {
        // Nexa
  },
    data(){
        return{
           sidebarCollapsed: false,
            isSidebarVisible: false,
            isFullWidth: false,
            useCases: [],
            questionDocuments: [],
            completedPercentage: 0,
            expandedIndex: null,
            followUpQuestion: '', 
            language: 'en',
            location_id: 1,
            question_doc: null,
        }
    },
    methods:{
        goBack() {
        this.$router.push({ name: 'ExpertMode' });
        },
        toggleSidebar() {
        this.sidebarCollapsed = !this.sidebarCollapsed;
        },
        toggleWidth(index) {
      if (this.expandedIndex === index) {
        // If the clicked card is already expanded, collapse it
        this.expandedIndex = null;
      } else {
        // Expand the clicked card
        this.expandedIndex = index;
      }
    },
     getFileIcon(fileType) {
      // Return an appropriate icon based on the file type
      if (fileType === "pdf") return "picture_as_pdf";
      if (fileType === "docx") return "description";
      // Add more file types as needed
      return "insert_drive_file"; // Default icon
    },
    formatFileSize(size) {
      // If you have file size, you can format it here. Otherwise, use a placeholder.
      return size ? `${(size / 1024).toFixed(2)} MB` : 'Unknown size'; // Adjust if the size unit is different
    },
    deleteFile(index) {
      // Remove the file from the list
      this.questionDocuments.splice(index, 1);
    },
    sendQuestion(id) {
      // Build the request body
      const questionDocArray = Array.isArray(this.question_doc) ? this.question_doc : [];
      const requestBody = {
        language: this.language,
        location_id: this.location_id,
        question: this.followUpQuestion,
        question_doc: questionDocArray, // This can be a file if you handle file uploads
        questionsPrompts: [], // Add any prompts you need here
        questions: [], // You can fill this with the current questions and answers
        qna: {}, // Your qna structure goes here
      };

      if (!this.followUpQuestion) {
         this.$toast.error("Please type question");
        return;
      }

      // Send the follow-up question using the DetailAsk API
      nexa.DetailAsk(id, requestBody)
        .then((response) => {
          // Handle the response, e.g., update the relevant use case with additional details
          const newResponse = response.data.data; // Example, adapt based on your API response structure
          console.log(newResponse);
          // Clear the input field
          this.followUpQuestion = '';
          this.$toast.success("Successful");
        })
        .catch((error) => {
          console.error("Error sending question:", error);
        });
    },
    },
   created() {
    nexa
    .GetResult(this.$route.params.id)
    .then(response => {
      this.completedPercentage = response.data.data.completedPercentage;
      const questions = response.data.data.questions;
      console.log(questions);

      // Map the API response to use cases and include the question ID
      this.useCases = questions.map(question => {
        // Check if answer exists and is not null
        let description = '';
        if (question.answer) {
          try {
            // Parse the answer and check if 'string' exists
            const parsedAnswer = JSON.parse(question.answer);
            description = parsedAnswer.string || '';
          } catch (e) {
            console.error('Error parsing answer:', e);
          }
        }

        // Return the use case with title, description, and id
        return {
          id: question.id, // Add question ID to the use case object
          title: question.question,
          description: description
        };
      });

      this.questionDocuments = response.data.data.questionDocuments;
    })
    .catch(error => {
      console.error('Error fetching result:', error);
    });
},


}
</script>

<style scoped>
.page-content{
  padding: 0;
}
.container {
  background: var(--Neutral-Interface-Grey, #fafbfc);
  display: flex;
  flex-direction: column;
  padding-bottom: 14px;
}

.page-header {
  background-color: #fff;display: flex;
  justify-content: space-between;
  padding: 16px 20px;
  flex-wrap: wrap;
}

.branding {
  display: flex;
  align-items: center;
  gap: 18px;
}

.page-title {
  color: #383a3e;
  font-size: 24px;
  font-weight: 600;}

.logo {
  width: 40px;
  height: auto;
}

.main-nav {
  display: flex;
  align-items: center;
  gap: 20px;
}

.upload-button,.sidebar-button {
  display: flex;
  align-items: center;
  border: none;
  border-radius: 4px;
  background-color: #0e4485;
  color: #fff;
  padding: 8px 16px;
  font-size: 16px;
  cursor: pointer;
}

.icon {
  width: 24px;
  height: auto;
  margin-right: 8px;}
.delete-icon{
    color: var(--status-red);
}
.main-content {
  padding: 0 14px;
}

.back-button {
  display: flex;
  align-items: center;
  border: none;
  border-radius: 4px;
  color: #0e4485;
  background: none;
  padding: 8px 16px;
  cursor: pointer;}

.uploads {
  background-color: #fff;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(227, 229, 235, 0.6);
  margin-top: 24px;
}

.file-list {
  list-style: none;
  padding: 0;
  margin: 24px 0;
}.file-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-radius: 8px;
  background-color: #fafbfc;
  margin-bottom: 8px;
}

.file-icon {
 display: flex;
width: 40px;
height: 40px;
padding: 8px;
justify-content: center;
align-items: center;
flex-shrink: 0;
border-radius: 8px;
border: 1px solid var(--Neutral-Light-Grey, #F2F3F3);
color: var(--primary);
}.delete-button {
  border: none;
  background: none;
  cursor: pointer;
}
.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.add-files-button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 4px;
  color: #0e4485;
  background: none;
  padding: 8px 16px;
  cursor: pointer;
}


.use-cases {
  margin-top: 35px;
}

.use-cases-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  border-bottom: 1px solid #d1d2d5;
}.use-cases-header h4 {
  font-size: 20px;
  font-weight: 600;
}

.search-bar {
  display: flex;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #f2f3f3;
  padding: 8px 20px;
}.use-case-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 20px;
}

.use-case {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(227, 229, 235, 0.6);
  padding: 24px;
  min-width: 240px;
  flex-grow: 1;
  max-width: 50%;
  transition: max-width 0.3s ease; /* Optional transition for smoothness */
  cursor: pointer;
}

.full-width {
  max-width: none; /* Remove max-width when this class is applied */
}
  .use-case:hover{
  outline: 1px solid #1890ff;
  }

.use-case h5 {
  font-size: 16px;
  font-weight: 500;
  color: #383a3e;
}

.use-case p {
  font-size: 14px;
  color: #86888d;
  margin-top: 8px;
  line-height: 24px;}
  
.breadcrumb_header{
  display: flex;
  justify-content: space-between;
  padding: 5px;
  background: #fff;
}

.breadcrumb {
  display: flex;
  align-items: center;
  margin: auto 0;
  span{
    font-size: 14px;
    color: var(--dark-grey) !important;
  }
}
.open_sidebar {
  height: 40px;
  background: var(--primary);
  display: inline-flex;
  padding: 4px 16px;
  justify-content: center;
  align-items: center; /* Ensure vertical alignment */
  gap: 8px;
  border-radius: 4px;
}

.open_sidebar span {
  color: #fff;
  display: flex; /* Ensure icon is aligned vertically */
  align-items: center; /* Align icon vertically within span */
}

.open_sidebar p {
  color: #fff !important;
  margin: 0; /* Remove any default margin to align the text properly */
  line-height: 1; /* Ensure text has the same line height as the icon */
  display: flex;
  align-items: center; /* Align text vertically */
}

.divider {
  display: inline-block;
  margin: 0px 16px;
  width: 1px;
  height: 32px;
  background-color: var(--mid-grey);
}
.back {
  color: var(--primary) !important;
  font-weight: 500;
  padding: 5px;
}
.use-case-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Styling the header text */
.use-case-header h2 {
  font-size: 1.5rem; /* Adjust the size */
  font-weight: 600; /* Semi-bold for emphasis */
  color: #333; /* Darker color for readability */
  margin: 0;
}

.use-case-header p {
  color: #888888; /* Light gray for the subtext */
  font-size: 0.875rem; /* Smaller font size for description */
  margin: 0;
  margin-top: 4px; /* Adds slight spacing below the heading */
}

.filter-search {
  display: flex;
  gap: 10px;
  align-items: center;
}

.filter-button {
  font-size: 0.875rem;
  border: 1px solid #ccc;
  color: #333; /* Dark text */
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.filter-button:hover {
  background-color: #f0f0f0; 
}

.search-input::placeholder {
  color: #888888;
}

.icon-search{
  color: #888888;
}
.search-container {
  display: flex;
  align-items: center;
  padding: 8px 8px;
  gap: 4px;
  background: var(--Neutral-White, #fff);
  border: 1px solid var(--Neutral-Mid-Grey, #d1d2d5);
  border-radius: 4px;}
  .file-title{
    color: var(--Neutral-Black, #383A3E);
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 171.429% */
  }
  .mb-title{
    color: var(--Neutral-Dark-Grey, #86888D);
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
  }
</style>

<style scoped>
.content-search-section {
  border-radius: 8px;
  border: 1px solid var(--Status-Blue, #1890ff);
  background: var(--Neutral-White, #fff);
  box-shadow: 0 4px 10px 0 rgba(227, 229, 235, 0.6);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  font-family: Poppins, sans-serif;
  padding: 24px 24px 44px;
}

.search-header {
  margin-bottom: 8px;
}

.search-title {
  color: var(--Neutral-Black, #383a3e);
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  margin: 0;
}

.search-description {
  color: var(--Neutral-Dark-Grey, #86888d);
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  margin: 8px 0 0;
}

.search-content {
  border-radius: 8px;
  background-color: rgba(24, 144, 255, 0.1);
  padding: 8px;
  font-size: 14px;
  color: var(--Neutral-Dark-Grey, #86888d);
  font-weight: 400;
  line-height: 24px;
}

@media (max-width: 991px) {
  .content-search-section {
    padding: 0 20px;
  }

  .search-description,
  .search-content {
    max-width: 100%;
  }
}.ask-container {
    display: flex;
    width: 100%;
    padding: 12px 20px;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    border-radius: 16px;
    border: 1px solid var(--Primary-Blue, #0E4485);
    background: var(--Neutral-White, #FFF);
    box-shadow: 0px 2px 10px 0px rgba(14, 68, 133, 0.60);
}

.ask-container input {
    flex-grow: 1;
    border: none;
    outline: none;
    font-size: 14px;
    padding: 8px;
    width: 100%;
    min-width: 0;
}

.ask-container .material-symbols-rounded.icon {
    font-size: 22px;
    cursor: pointer;
    color: #0E4485;
}

</style>
<style scoped>
.main-container {
  width: 100%;
  position: relative;
}

.overlay-container {
  width: 25%;
  position: absolute;
  top: 10%;
  right: 0;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.5);
  height: 100vh;
}
.breadcrumb {
  display: flex;
  align-items: center;
  span{
    font-size: 14px;
    color: var(--dark-grey) !important;
  }
}
.open_sidebar {
  height: 40px;
  background: var(--primary);
  display: inline-flex;
  padding: 4px 16px;
  justify-content: center;
  align-items: center; /* Ensure vertical alignment */
  gap: 8px;
  border-radius: 4px;
}

.open_sidebar span {
  color: #fff;
  display: flex; /* Ensure icon is aligned vertically */
  align-items: center; /* Align icon vertically within span */
}

.open_sidebar p {
  color: #fff !important;
  margin: 0; /* Remove any default margin to align the text properly */
  line-height: 1; /* Ensure text has the same line height as the icon */
  display: flex;
  align-items: center; /* Align text vertically */
}

.container {
  background: var(--Neutral-Interface-Grey, #fafbfc);
  display: flex;
  flex-direction: column;  align-items: center;
  padding: 100px 70px 14px;
}

@media (max-width: 991px) {
  .container {
    padding: 0 20px;
  }
}.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1076px;
}

@media (max-width: 991px) {
  .content {
    max-width: 100%;  padding-right: 20px;
  }
}

.header {
  text-align: center;
  max-width: 100%;
}
.title {
  color: var(--Neutral-Black, #383a3e);
  font-family: Poppins, sans-serif;
  font-size: 32px;
  font-weight: 600;
}

.subtitle {
  color: var(--Neutral-Dark-Grey, #86888d);
  font-family: Poppins, sans-serif;
  font-size: 14px;font-weight: 400;
  line-height: 24px;
}

.search-bar {
  border: 1px solid var(--Neutral-Light-Grey, #f2f3f3);
  border-radius: 8px;
  background: var(--Neutral-White, #fff);
  box-shadow: 0px 4px 10px 0px rgba(227, 229, 235, 0.6);
  margin-top: 24px;
  width: 100%;
}

.input-group {
  padding: 16px;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  position: relative;
}.input-field {
  flex: 1;
  color: var(--Neutral-Mid-Grey, #000);
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  padding: 12px;
  border: none;
  outline: none;}

.upload-button {
  border-radius: 4px;
  overflow: hidden;
  padding: 4px;
  padding-right: 10px;
  right: 0;
  position: absolute;
}

.upload-button img {
  width: 20px;object-fit: contain;
}

.tooltip {
  align-self: start;
  border-radius: 4px;
  color: var(--Neutral-White, #fff);
  display: flex;
  flex-direction: column;
  gap: 8px;padding: 6px 8px;
  margin-top: -14px;
}

.tooltip-arrow {
  position: absolute;
  left: 16px;
  bottom: 8px;
  width: 11px;
  height: 6px;
  background: var(--Neutral-Black, #383a3e);
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
}
.search-bar-footer {
  background: #FAFBFC;
  border-top: 1px solid var(--Neutral-Light-Grey, #f2f3f3);
  display: flex;
  justify-content: space-between;
  padding:6px 20px;
  margin-top: -16px;
}
.attach-button,
.country-button {
  color: #86888D;
  font-size: 14px;
  font-weight: 500;
}
.char-count {
  color: var(--Neutral-Mid-Grey, #d1d2d5);
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
}

.prompts {margin-top: 24px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}
.prompt-button {
  background: var(--Neutral-Light-Grey, #f2f3f3);
  border: 1px solid var(--Neutral-Mid-Grey, #d1d2d5);
  border-radius: 16px !important;
  box-shadow: 0px 4px 10px 0px rgba(227, 229, 235, 0.6);
  color: var(--Neutral-Black, #383a3e);
  font-family: Poppins, sans-serif;
  font-size: 12px;
  font-weight: 400;
  padding: 6px 12px;
}.load-more-button {
  background: none;
  color: var(--Primary-Blue, #0e4485);
  font-size: 14px;
  font-weight: 500;
  padding: 2px 8px;
}

.use-cases {
  margin-top: 35px;
}.use-cases-header {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
}

.use-cases-title {
  max-width: 218px;
}.use-cases-controls {
  display: flex;
  gap: 20px;
}
.filter-button{
  display: flex;
  align-items: center;
  gap: 4px;
  border-radius: 4px;}
  .search-container{
  padding: 8px 8px;
}


.search-container {
  display: flex;
  align-items: center;
  gap: 4px;
  background: var(--Neutral-White, #fff);
  border: 1px solid var(--Neutral-Mid-Grey, #d1d2d5);
  border-radius: 4px;}
  .search-container{
  padding: 8px 8px;
  }
.search-field {
  border: none;
  outline: none;
}

.use-case-grid {
  margin-top: 11px;
  display: flex; flex-wrap: wrap;
  gap: 20px;
}

.use-case-card {
  height: 212px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 4px 10px 0px rgba(227, 229, 235, 0.6);
  flex: 1;
  padding: 24px;
  flex: 1 1 240px;
}
.use-case-card:hover{
  outline: 1px solid var(--primary);
}
.use-case-title {
  color: var(--Neutral-Black, #383a3e);
  font-size: 20px;
  font-weight: 600;
}.use-case-description {
  color: var(--Neutral-Dark-Grey, #86888d);
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  margin-top: 16px;
}
.create-button {
  display: none;
  width: 240px;
  align-self: end;
  background: var(--Primary-Blue, #0e4485);
  border-radius: 4px;
  color: var(--Neutral-White, #fff);
  font-size: 16px;
  font-weight: 500;
  padding: 4px 16px;
  margin-top: 16px;
}
.use-case-card:hover .create-button {
  display: block; 
}
.decorative-image {
  width: 20px;
  object-fit: contain;
  margin-top: 204px;
}

@media (max-width: 991px) {
  .decorative-image {
    margin-top: 40px;
  }
}
</style>
<style scoped>
.sidebar {
  background: var(--Neutral-White, #fff);
  box-shadow: 0px 4px 10px rgba(170, 171, 175, 0.6);
  display: flex;
  max-width: 300px;flex-direction: column;
  overflow: hidden;
  font-family: Poppins, sans-serif;
  padding: 24px 12px;
}

.sidebar-toggle,
.new-chat-button,
.show-all-button {
  border-radius: 4px;
  display: flex;
  width: 100%;
  align-items: center;
  gap: 8px;
  font-size: 16px;font-weight: 500;
  justify-content: flex-start;
  padding: 4px 16px;
  background: none;
  border: none;
}

.icon,
.search-icon {
  width: 24px;
  height: 24px;
}
.icon-small {
  color: #86888D;
  margin: auto;
  font-size: 18px;
}
.icon-filter{
  margin: auto;
  font-size: 18px;
}

.sidebar-header {
  margin-top: 24px;
  width: 100%;
  display: flex;
  flex-direction: column;
}.new-chat-button {
  background-color: var(--Primary-Blue, #0e4485);
  color: var(--Neutral-White, #fff);
}

.search-form {
  margin-top: 11px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  background-color: var(--Neutral-Light-Grey, #f2f3f3);
  padding: 6px 12px;
  width: 100%;
}.search-input {
  flex: 1;
  border: none;
  background: none;
  outline: none;
  font-size: 16px;
  color: var(--Neutral-Dark-Grey, #86888d);
}

.search-icon {
  width: 24px;
  height: 24px;
}.item-list {
  margin-top: 24px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.separator {
  border: 1px solid #f2f3f3;
  width: 100%;
}

.recent-logs-title {
  margin-top: 8px;
  font-size: 16px;
  color: var(--Neutral-Dark-Grey, #86888d);}

.conversation-card,
.alt-card {
  border-radius: 8px;
  padding: 6px;
  display: flex;
  flex-direction: column;
  margin-top: 8px;
}

.alt-card {
  background-color: var(--Neutral-Light-Grey, #f2f3f3);
}.conversation-title {
  font-size: 14px;
  color: var(--Neutral-Black, #383a3e);
  margin: 0;
}

.conversation-details {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  color: var(--Neutral-Dark-Grey, #86888d);
}

.file-upload-info {
  flex: 1;}

.sidebar-image {
  position: absolute;
  right: 77px;
  bottom: 97px;
  width: 20px;
  height: 21px;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;margin: -1px;
  overflow: hidden;
  clip: rect(0,0,0,0);
  border: 0;
}
.upload-button{
  span{
    color: #86888D;
  }
}
input::placeholder{
font-size: 16px;
font-weight: 400;
line-height: 28px;
text-align: left;
color: #D1D2D5;
}
.use-case-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
}

/* Styling the header text */
.use-case-header h2 {
  font-size: 1.5rem; /* Adjust the size */
  font-weight: 600; /* Semi-bold for emphasis */
  color: #333; /* Darker color for readability */
  margin: 0;
}

.use-case-header p {
  color: #888888; /* Light gray for the subtext */
  font-size: 0.875rem; /* Smaller font size for description */
  margin: 0;
  margin-top: 4px; /* Adds slight spacing below the heading */
}

/* Styling the filter and search area */
.filter-search {
  display: flex;
  gap: 10px;
  align-items: center;
}

.filter-button {
  font-size: 0.875rem;
  border: 1px solid #ccc;
  color: #333; /* Dark text */
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.filter-button:hover {
  background-color: #f0f0f0; 
}

.search-input::placeholder {
  color: #888888;
}

.icon-search{
  color: #888888;
}
.country-option{
  left: 18%;
  width: 100px !important;
  background: #FAFBFC;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 12px;
  position: absolute;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.country-flag {
  width: 18px;
  height: 18px;
  margin-right:10px;
}
.countryOption{
  cursor: pointer;
 margin-bottom: 10px;
 span{
  color:#86888D;
  font-size:14px;
 }
}
.divider {
  display: inline-block;
  margin: 0px 16px;
  width: 1px;
  height: 32px;
  background-color: var(--mid-grey);
}
.close-icon {
  font-size: 18px;
  color: var(--status-red);
  cursor: pointer;
}
.modal-icon{
  width: 24px;
  height: 24px;
  color: #383A3E;
  cursor: pointer;
}
.upload{
  background: var(--primary);
  border-radius: 6px;
  display: flex;
  padding: 4px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: #fff;
}
.swift{
  border-radius: 4px;
background: rgba(24, 144, 255, 0.10);
display: flex;
padding: 8px 16px;
align-items: center;
gap: 8px;
align-self: stretch;
}
.toggle {
    display: flex;
    width: 40px;
  }.switch {
    flex-grow: 1;
    background: var(--Neutral-Mid-Grey, #d1d2d5);
    border-radius: 100px;
    padding: 2px;
    display: flex;
    align-items: center;}.button {
    width: 16px;
    height: 16px; background: var(--Neutral-White, #fff);
    border-radius: 100px;
    box-shadow: 0 2px 4px rgba(39, 39, 39, 0.1);
  }
  .file-list{
    display: flex;
    gap: 10px;
  }
  .footer-text{
    color: var(--Neutral-Dark-Grey, #86888D) !important;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  }
  .skeleton{
    display: flex;
    height: 40px;
    justify-content: flex-end;
    align-items: center;
    border-radius: 12px;
    background: #F3F3F3;
  }
</style>
